import React, { useState } from "react";
import userService from "../../services/userService";
import { Button, Form } from "react-bootstrap";
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import moment from "moment/moment";
export default function SubmitApplication({
  user,
  userData,
  updatePage,
  handleNav,
  shake,
}) {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [validated, setValidated] = useState(false);
  const date = moment().format();

  const [modalShow, setModalShow] = useState(false);
  const onModalHide = () => {
    setModalShow(false);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false && !termsAccepted) {
      e.preventDefault();
      e.stopPropagation();
      shake();
    } else if (
      (userData && !userData.personalInfo) ||
      !userData.general ||
      !userData.residenceInfo ||
      !userData.employment ||
      (!userData.backgroundInfo && form.checkValidity())
    ) {
      e.preventDefault();
      e.stopPropagation();
      setModalShow(true);
    } else {
      userService.saveUserData(user, "applicationStatus", "complete");
      userService.saveUserData(user, "termsAccepted", termsAccepted);
      updatePage();
      userService.saveUserData(user, "submissionDate", date);
      updatePage();
    }
    setValidated(true);
  };

  return (
    <main>
      <Modal show={modalShow} centered>
        {" "}
        <ModalHeader as="span" className="alert alert-warning">
          <ModalTitle as="h4">Incomplete Tasks!</ModalTitle>
        </ModalHeader>
        <ModalBody as="section">
          <p>You have unfinished tasks!</p>
          <p>
            To submit application, it is mandatory to complete all tasks in both
            phase 1 and phase 2. Please click "Confirm" to return to the
            application and ensure the completion of all tasks highlighted in
            red in Phase 1 and Phase 2.
          </p>
        </ModalBody>
        <ModalFooter as="footer">
          <Button onClick={onModalHide}>Confirm</Button>
        </ModalFooter>
      </Modal>
      {userData.applicationStatus === "complete" ? (
        <section className="main-section">
          <h2>Submit Success</h2>
          <section className="main-section-content text-start">
            <p>
              <strong>Congratulations! </strong>
            </p>

            <p>Your application was successfully submitted!</p>

            <p> Our office will be in contact with you soon.</p>

            <p>
              If you want to get started on the new hire requirements, feel free
              to continue to Phase 4.
            </p>
            <p>
              *** Note: Phase 4 is not required prior to offer. Phase 4 is for
              applicants who have been offered a position pending background
              check ***
            </p>
            <div className="d-flex justify-content-between">
              <Button
                variant="primary"
                onClick={() => handleNav("phase4-upload-documents")}
              >
                Upload Documents
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  alert(
                    "By clicking this button, you will gain access to pre-employment training and/or tests"
                  )
                }
              >
                Access to pre-employment tests will live here
              </Button>
            </div>
            <Button className="mt-5" variant="primary">
              Logout
            </Button>
          </section>
        </section>
      ) : (
        <section>
          <section className="main-section">
            <h2>Submit Application</h2>
            {/* Page Instructions  */}
            <section className="main-section-content text-start">
              <p>
                <strong>
                  Congratulations! You have provided all required information!
                </strong>{" "}
              </p>
              <p>
                Review and accept terms in the "Terms and Conditions" section
              </p>
              <p>After terms are accepted click "Submit Application"</p>
              <div className="alert alert-info">
                <p>Requirements: Review and accept Terms. Submit Application</p>
                <p className="mb-0">
                  You must click "Submit Application" in the "Terms and
                  Conditions" section for your application to be submitted.
                </p>
              </div>
            </section>
          </section>

          {/* Terms and Conditions  */}
          <section className="main-section">
            <h2>Terms and Conditions</h2>
            <div className="main-section-content">
              <section className="text-start">
                <p>Terms or link to terms here</p>
                <Form onSubmit={handleSubmit} validated={validated} noValidate>
                  <p>
                    By clicking the checkbox and submitting your application,
                    you are agreeing to the terms and agreements....disclaimer?
                  </p>
                  <Form.Check
                    className="mb-3"
                    type="checkbox"
                    id="application-terms"
                    label="I agree to the terms and agreements"
                    name="application-terms"
                    onChange={() => setTermsAccepted(!termsAccepted)}
                    required
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                  <section>
                    {termsAccepted ? (
                      <p className="alert alert-success text-start">
                        Terms accepted. Click the button below to submit your
                        application
                      </p>
                    ) : (
                      <p className="alert alert-danger text-start">
                        Please accept terms
                      </p>
                    )}
                  </section>

                  <section className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                    >
                      Submit Application
                    </Button>
                  </section>
                </Form>
              </section>
            </div>
          </section>
        </section>
      )}
    </main>
  );
}
