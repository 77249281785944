import { object, string, array } from "yup";

const validationSchema = object().shape({
  office: string().required("Select office of interest"),
  poi: string().required("Select position of interest"),
  daysAvailable: array().required("Select days available"),
  timesAvailable: array().required("Select days available"),
  startDate: string()
    .min(8, "Enter in the format shown MM/DD/YYYY")
    .required("Start date is required "),
  validLicense: string().required("Required"),
  carAndIns: string().required("Required"),
});

const offices = [
  {
    name: "HomeChoice of the Triangle",
    locationOffices: [
      "Raleigh/Durham",
      "Cary",
      "Fuquay Varina",
      "Garner",
      "Apex",
    ],
  },
  {
    name: "HomeChoice of the Sandhills",
    locationOffices: [
      "Southern Pines",
      "Pinehurst",
      "Aberdeen",
      "Sanford",
      "Fayetteville",
      "Pine Bluff",
      "Rockingham",
    ],
  },
  {
    name: "HomeChoice of Eastern Carolina",
    locationOffices: [
      "Goldsboro",
      "Kinston",
      "Pikeville",
      "Smithfield",
      "Greenville",
    ],
  },
];

const poi = [
  "Personal Care Aide",
  "Certified Nurse Aide",
  "Registered Nurse",
  "Office Worker",
  "Supervisor in Charge",
  "Med Tech",
  "Housekeeper",
  "Resident Care Coordinator",
  "Transportation",
  "Activities Director",
  "Kitchen Staff",
  "Maintenance",
  "Dietary",
];

const dayAvailabilityArr = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const timeAvailabilityArr = [
  "12:00am - 4:00am",
  "4:00am - 8:00am",
  "8:00am - 12:00pm",
  "12:00pm - 4:00pm",
  "4:00pm - 8:00pm",
  "8:00am - 12:00am",
];

const languagesArr = [
  "English",
  "Spanish",
  "Chinese",
  "Tagalog",
  "Vietnamese",
  "French/French Creole",
  "Arabic",
  "Korean",
  "Russian",
  "German",
  "Other",
];

export {
  validationSchema,
  offices,
  poi,
  dayAvailabilityArr,
  timeAvailabilityArr,
  languagesArr
};
