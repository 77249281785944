import React from "react";
import { Container } from "react-bootstrap";
import PBLogo from "../../assets/images/pb-logo.png";
import Version from "../common/version";

export default function Header() {
  return (
    <header>
      <Container
        className="text-end d-flex align-items-center justify-content-between"
        fluid
      >
        <Version />
        <img src={PBLogo} height="25px" />
      </Container>
    </header>
  );
}
