import { object, string } from "yup";

const validationSchema = object().shape({});

const signupInputs = [
  {
    name: "email",
    label: "Email *",
    type: "email",
    placeholder: "example@email.com",
  },
  {
    name: "firstName",
    label: "First Name *",
    type: "text",
    placeholder: "Enter First Name",
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: "text",
    placeholder: "Enter Last Name",
  },
  {
    name: "mobileNumber",
    label: "Mobile Number *",
    type: "text",
    placeholder: "Enter Mobile Number",
  },
  {
    name: "password",
    label: "Password *",
    type: "text",
    placeholder: "Enter Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password *",
    type: "text",
    placeholder: "Enter Password",
  },
];

export { signupInputs, validationSchema };
