import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";

export default function RequiredFieldMessage({
  handleClick,
  show,
  reqMessage,
  hasButton,
  fieldMessage,
}) {
  return (
    <Alert className="text-start" key="danger" variant="danger" show={show}>
      {fieldMessage ? (
        <strong>
          <p className="mb-0">Complete the required fields</p>
        </strong>
      ) : (
        <strong>
          <p>Page Requirements Not Met</p>
        </strong>
      )}

      {reqMessage && <p>{reqMessage}</p>}

      {hasButton && (
        <>
          <hr />
          <p>
            If you do not have all the neccesary information, click "Skip This
            Step" and return to this step when you have all required
            information.
          </p>
          <Button variant="link" onClick={handleClick}>
            Skip This Step
          </Button>
        </>
      )}
    </Alert>
  );
}
