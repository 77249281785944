import React, { useState, useEffect } from "react";
import './App.css';
import { BrowserRouter as Router, Switch, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import userService from "./services/userService";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';

import Header from './components/layout/header';

import NavComponent from './components/layout/nav';


import Login from "./components/pages/login";
import Signup from "./components/pages/signup";
import ResetPassword from "./components/pages/resetPassword";
import PageNotFound from "./components/pages/page-not-found";
import Loader from "./components/common/loader";
import Welcome from './components/pages/welcome';
import General from './components/pages/general'
import References from './components/pages/references';
import Employers from './components/pages/employers';
import Residences from './components/pages/residences';
import Education from './components/pages/education';
import PersonalInformation from './components/pages/personalInformation';
import BackgroundInformation from './components/pages/bg-info';
import UploadDocuments from './components/pages/upload-docs'
import SubmitApplication from './components/pages/submit-application';
import ScrollToTop from "./components/common/scroll-to-top";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageUpdate, setPageUpdate] = useState(null);
  const [user, setUser] = useState("lizzy@sembracare.com");
  const [userData, setUserData] = useState(null);
  const [enablePhases, setEnablePhases] = useState({
    phase2: false,
    phase3: false,
    phase4: false,
  });

  const nav = [
    { name: "Welcome", route: "/" },
    { name: "Personal Information", route: "/phase1-personal-information" },
    { name: "General Information", route: "/phase2-general" },
    { name: "Residence History", route: "/phase2-residences" },
    { name: "Employers", route: "/phase2-employers" },
    { name: "References", route: "/phase2-references" },
    { name: "Education", route: "/phase2-education" },
    { name: "Background Information", route: "/phase2-background-information" },
    { name: "Submit Application", route: "/phase3-submit-application" },
    { name: "Upload Documents", route: "/phase4-upload-documents" },
    { name: "Login", route: "/" },
    { name: "Signup", route: "/signup" },
    { name: "Reset Password", route: "/reset-password" },
    { name: "404", route: "*" }
  ]

  const handleNav = (activePage) => {
    navigate(`/${activePage}`);
  }

  const handlePhases = (data) => {
    if (data.applicationStatus === "complete") {
      setEnablePhases({ ...enablePhases, phase2: true, phase3: true, phase4: true })
    } else if (data.general && data.residenceInfo && data.residenceInfo.isComplete && data.employment && data.referenceInfo && data.referenceInfo.isComplete && data.education && data.education.isComplete && data.backgroundInfo) {
      setEnablePhases({ ...enablePhases, phase2: true, phase3: true })
    } else if (data.username && data.personalInfo) {
      setEnablePhases({ ...enablePhases, phase2: true })
    } else {
      setEnablePhases(enablePhases);
    }
  }

  const [isLoading, setIsLoading] = useState(true);

  const pullUserData = async () => {
    const { data } = await userService.getUserData(user)
    if (data === "No data connected to this username") {
      setUserData({});
      setIsLoading(false);
    } else {
      setUserData(data[0]);
      setIsLoading(false);
    }
    handlePhases(data[0]);
    checkPrevTasks(data[0]);
  }

  const updatePage = () => {
    setPageUpdate(!pageUpdate)
  }

  const shake = () => {
    // "use strict";
    const shakeElement = document.querySelector(".shakeMe");
    shakeElement.classList.add("animate");
    setTimeout(() => {
      shakeElement.classList.remove("animate");
    }, 1000);
  };

  const [allTasksCompleted, setAllTasksCompleted] = useState(false);
  const checkPrevTasks = (data) => {
    if (
      data.personalInfo &&
      data.general &&
      data.residenceInfo &&
      data.residenceInfo.isComplete &&
      data.referenceInfo &&
      data.referenceInfo.isComplete &&
      data.employment &&
      data.employment.isComplete &&
      data.education &&
      data.education.isComplete
    ) {
      setAllTasksCompleted(true)
    }
  };

  useEffect(() => {
    pullUserData();
  }, [location.pathname, pageUpdate])



  return (
    <>
      {
        isLoading ? (
          <Loader />
        ) : (
          <div className="app">
            <ScrollToTop />
            <Header />
            {userData && Object.keys(userData).length >= 1 ? (
              <Container fluid className="main-container text-center">
                <section className="main-content" style={{ minHeight: '100vh' }}>
                  <Row>
                    {/* Navigantion  */}
                    <Col lg={3}>
                      <NavComponent userData={userData} nav={nav} enablePhases={enablePhases} />
                    </Col>

                    {/* Main Content  */}
                    <Col className="shakeMe">
                      <Routes>
                        {/* Phase 1 */}
                        <Route exact path={"/"} element={<Welcome userData={userData} handleNav={handleNav} />} />

                        <Route exact path={"phase1-personal-information"} element={<PersonalInformation user={user} userData={userData} handleNav={handleNav} shake={shake} />} />



                        {/* Phase 2 */}
                        <Route exact path={nav[2].route} element={<General user={user} userData={userData} handleNav={handleNav} shake={shake} />} />

                        <Route exact path={nav[3].route} element={<Residences user={user} userData={userData} handleNav={handleNav} updatePage={updatePage} shake={shake} />} />

                        <Route exact path={nav[4].route} element={<Employers user={user} userData={userData} handleNav={handleNav} updatePage={updatePage} shake={shake} />} />

                        <Route exact path={nav[5].route} element={<References user={user} userData={userData} handleNav={handleNav} updatePage={updatePage} shake={shake} />
                        } />

                        <Route exact path={nav[6].route} element={<Education user={user} userData={userData} handleNav={handleNav} updatePage={updatePage} shake={shake} />} />

                        <Route exact path={nav[7].route} element={<BackgroundInformation user={user} userData={userData} handleNav={handleNav} updatePage={updatePage} shake={shake} enablePhases={enablePhases} allTasksCompleted={allTasksCompleted} />} />



                        {/* Phase 3 */}
                        <Route exact path={nav[8].route} element={<SubmitApplication user={user} userData={userData} handleNav={handleNav} updatePage={updatePage} shake={shake} />} />

                        {/* Phase 4 */}
                        <Route exact path={nav[9].route} element={<UploadDocuments user={user} userData={userData} handleNav={handleNav} updatePage={updatePage} shake={shake} />} />

                      </Routes>
                    </Col>
                  </Row>
                </section>
              </Container>
            ) : (
              <section className="main-content">
                <Routes>
                  {/* Public Facing */}
                  <Route exact path={nav[10].route} element={<Login userData={userData} handleNav={handleNav} pullUserData={pullUserData} />} />
                  <Route exact path={nav[11].route} element={<Signup userData={userData} handleNav={handleNav} />} />
                  <Route exact path={nav[12].route} element={<ResetPassword userData={userData} handleNav={handleNav} />} />
                  <Route exact path={nav[13].route} element={<PageNotFound userData={userData} handleNav={handleNav} />} />
                </Routes>
              </section>
            )
            }
          </div >
        )
      }
    </>


  );
}

export default App;
