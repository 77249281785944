import React, { useEffect } from "react";
import LoginForm from "../forms/pageForms/loginForm";
import { Link } from "react-router-dom";

export default function Login({}) {
  useEffect(() => {}, []);

  const handleFormikSubmit = (values, actions) => {
    console.log("Values: ", values);
    console.log("Actions: ", actions);
  };

  return (
    <main className="registration">
      <section className="main-section">
        <h2>Login</h2>
        <section className="main-section-content">
          <LoginForm handleFormikSubmit={handleFormikSubmit} />
          <hr />
          <section className="text-center mt-3">
            <div className="d-flex justify-content-around">
              <p>Don't have an account?</p>
              <Link to="/signup">SIGN UP</Link>
            </div>

            <div className="d-flex text-start mt-3">
              <Link to="/reset-password">Reset Password</Link>
            </div>
          </section>
        </section>
      </section>
    </main>
  );
}
