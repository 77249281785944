import React, { useState } from "react";
import { Formik } from "formik";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import RequiredFieldMessage from "../../common/userMessages/requiredFieldMessage";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { validationSchema } from "../formEntities/backgroundInfoEntities";
import SaveAndContinueUserMessage from "../../common/userMessages/saveAndContinueMessage";

const BackgroundForm = ({
  // showReqFieldMessage,
  shake,
  handleFormikSubmit,
  // handleSaveAndContinue,
}) => {
  const styles = {
    errorStyle: {
      border: "2px solid red",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
    fieldErrMessage: {
      color: "red",
      paddingLeft: "5px",
    },
    errIcon: {
      fontSize: "20px",
      color: "red",
    },
    inputGroupTextStyle: {
      border: "1px solid red",
      background: "white",
    },
    errText: {
      color: "red",
    },
  };

  const handlePageReqs = (data, errors) => {
    if (
      (data.nonCompete === "no" ||
        (data.nonCompete === "yes" &&
          data.nonCompeteCompany.length >= 2 &&
          // values.nonCompeteDate &&
          data.nonCompeteDate.length === 10)) &&
      (data.workInjury === "no" ||
        (data.workInjury === "yes" &&
          data.workInjuryDate.length === 10 &&
          data.workInjuryExplantation.length >= 2)) &&
      (data.workmanComp === "no" ||
        (data.workmanComp === "yes" &&
          data.workCompInjuryDate.length === 10 &&
          data.workCompExplanation.length >= 2)) &&
      (data.disability === "no" ||
        (data.disability === "yes" &&
          data.disabilityCompany.length >= 2 &&
          data.disabilityAmount.length >= 4)) &&
      (data.convictions === "no" ||
        (data.convictions === "yes" &&
          data.convictionsExplanation.length >= 2)) &&
      Object.keys(errors).length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleValidatations = (values) => {
    const errors = {};

    if (
      values.nonCompeteDate &&
      !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
        values.nonCompeteDate
      )
    ) {
      errors.nonCompeteDate = "Invalid date format.  Use MM/DD/YYYY";
    }

    if (
      values.workInjuryDate &&
      !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
        values.workInjuryDate
      )
    ) {
      errors.workInjuryDate = "Invalid date format.  Use MM/DD/YYYY";
    }

    if (
      values.workCompInjuryDate &&
      !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
        values.workCompInjuryDate
      )
    ) {
      errors.workCompInjuryDate = "Invalid date format.  Use MM/DD/YYYY";
    }

    if (
      values.disabilityAmount &&
      !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/i.test(
        values.disabilityAmount
      )
    ) {
      errors.disabilityAmount = "Invalid Format.  Use XX,XXX.XX";
    }

    if (handlePageReqs(values, errors)) {
      setShowReqFieldMessage(false);
    }
    return errors;
  };

  const [showReqFieldMessage, setShowReqFieldMessage] = useState(false);
  const handleSaveAndContinue = (values, errors) => {
    // console.log(errors);
    if (handlePageReqs(values, errors)) {
      setShowReqFieldMessage(false);
    } else {
      shake();
      setShowReqFieldMessage(true);
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        nonCompete: "",
        nonCompeteCompany: "",
        nonCompeteDate: "",
        workInjury: "",
        workInjuryDate: "",
        workInjuryExplantation: "",
        workCompExplanation: "",
        workmanComp: "",
        workCompInjuryDate: "",
        wmCompExpl: "",
        disability: "",
        disabilityCompany: "",
        disabilityAmount: "",
        convictions: "",
        convictionsExplanation: "",
      }}
      validate={(values) => handleValidatations(values)}
      onSubmit={(values) => handleFormikSubmit(values)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Container>
          {/* {console.log("Values: ", values)} */}
          <Form noValidate onSubmit={handleSubmit}>
            {/* Non compete  */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Non Compete Agreement</h3>
                <em>Required *</em>
              </div>
              <Row>
                <Col sm={12} className="mb-3 text-start">
                  <p
                    style={
                      errors.nonCompete && touched.nonCompete && styles.errText
                    }
                  >
                    Have you ever signed a non compete agreement? If yes,
                    additional fields will appear to collect additional required
                    information.
                  </p>
                  <Form.Check
                    inline
                    id="non-compete-radio-1"
                    type="radio"
                    label="Yes"
                    value="yes"
                    name="nonCompete"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    id="non-compete-radio-2"
                    type="radio"
                    label="No"
                    value="no"
                    name="nonCompete"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              {values.nonCompete === "yes" && (
                <Row>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group className="mb-3" controlId="nonCompeteCompany">
                      <Form.Label>Company name</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Enter company name"
                          name="nonCompeteCompany"
                          onChange={handleChange}
                          style={
                            errors.nonCompeteCompany &&
                            touched.nonCompeteCompany
                              ? styles.errorStyle
                              : null
                          }
                        />
                        {errors.nonCompeteCompany &&
                          touched.nonCompeteCompany && (
                            <InputGroup.Text style={styles.inputGroupTextStyle}>
                              <AiOutlineExclamationCircle
                                style={styles.errIcon}
                              />
                            </InputGroup.Text>
                          )}
                      </InputGroup>
                      {errors.nonCompeteCompany && touched.nonCompeteCompany ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.nonCompeteCompany}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group className="mb-3" controlId="nonCompDate">
                      <Form.Label>Date Signed</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="XX/XX/XXXX"
                          name="nonCompeteDate"
                          onChange={handleChange}
                          maxLength={10}
                          style={
                            errors.nonCompeteDate && touched.nonCompeteDate
                              ? styles.errorStyle
                              : null
                          }
                        />
                        {errors.nonCompeteDate && touched.nonCompeteDate && (
                          <InputGroup.Text style={styles.inputGroupTextStyle}>
                            <AiOutlineExclamationCircle
                              style={styles.errIcon}
                            />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      {errors.nonCompeteDate && touched.nonCompeteDate ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.nonCompeteDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </section>
            <hr />

            {/* Work related injury  */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Work Related Injury</h3>
                <em>Required *</em>
              </div>
              <Row>
                <Col sm={12} className="mb-3 text-start">
                  <p
                    style={
                      errors.workInjury && touched.workInjury && styles.errText
                    }
                  >
                    Have you ever had a work related injury? If yes, additional
                    fields will appear to collect additional required
                    information.
                  </p>
                  <Form.Group>
                    <Form.Check
                      inline
                      id="work-inj-radio-1"
                      type="radio"
                      label="Yes"
                      value="yes"
                      name="workInjury"
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      id="work-inj-radio-2"
                      type="radio"
                      label="No"
                      value="no"
                      name="workInjury"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {values.workInjury === "yes" && (
                <Row>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group
                      className="mb-3"
                      controlId="workRelatedInjuryDate"
                    >
                      <Form.Label>Injury Date</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="XX/XX/XXXX"
                          name="workInjuryDate"
                          onChange={handleChange}
                          maxLength={10}
                          style={
                            errors.workInjuryDate && touched.workInjuryDate
                              ? styles.errorStyle
                              : null
                          }
                        />
                        {errors.workInjuryDate && touched.workInjuryDate && (
                          <InputGroup.Text style={styles.inputGroupTextStyle}>
                            <AiOutlineExclamationCircle
                              style={styles.errIcon}
                            />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      {errors.workInjuryDate && touched.workInjuryDate ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.workInjuryDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group
                      className="mb-3"
                      controlId="workRelatedInjuryExplanation"
                    >
                      <Form.Label>If yes, please explain</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="workInjuryExplantation"
                        onChange={handleChange}
                        style={
                          errors.workInjuryExplantation &&
                          touched.workInjuryExplantation
                            ? styles.errorStyle
                            : null
                        }
                      />
                      {errors.workInjuryExplantation &&
                      touched.workInjuryExplantation ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.workInjuryExplantation}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </section>
            <hr />

            {/* Workman's Comp */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Workman's Comp</h3>
                <em>Required *</em>
              </div>
              <Row>
                <Col sm={12} className="mb-3 text-start">
                  <p
                    style={
                      errors.workmanComp &&
                      touched.workmanComp &&
                      styles.errText
                    }
                  >
                    Have you ever received workman's comp? If yes, additional
                    fields will appear to collect additional required
                    information.
                  </p>
                  <Form.Check
                    inline
                    id="work-comp-radio-1"
                    type="radio"
                    label="Yes"
                    value="yes"
                    name="workmanComp"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    id="work-comp-radio-2"
                    type="radio"
                    label="No"
                    value="no"
                    name="workmanComp"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              {values.workmanComp === "yes" && (
                <Row>
                  <p className="text-start">
                    Please provide additional information
                  </p>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group className="mb-3" controlId="workCompInjuryDate">
                      <Form.Label>Injury Date</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="XX/XX/XXXX"
                          name="workCompInjuryDate"
                          onChange={handleChange}
                          maxLength={10}
                          style={
                            errors.workCompInjuryDate &&
                            touched.workCompInjuryDate
                              ? styles.errorStyle
                              : null
                          }
                        />
                        {errors.workCompInjuryDate &&
                          touched.workCompInjuryDate && (
                            <InputGroup.Text style={styles.inputGroupTextStyle}>
                              <AiOutlineExclamationCircle
                                style={styles.errIcon}
                              />
                            </InputGroup.Text>
                          )}
                      </InputGroup>
                      {errors.workCompInjuryDate &&
                      touched.workCompInjuryDate ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.workCompInjuryDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group
                      className="mb-3"
                      controlId="workCompExplanation"
                    >
                      <Form.Label>
                        If yes, please explain all injuries. Include dates if
                        multiple injuries.
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleChange}
                        style={
                          errors.workCompExplanation &&
                          touched.workCompExplanation
                            ? styles.errorStyle
                            : null
                        }
                      />
                      {errors.workCompExplanation &&
                      touched.workCompExplanation ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.workCompExplanation}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </section>
            <hr />

            {/* Disability  */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Disability</h3>
                <em>Required *</em>
              </div>
              <Row>
                <Col sm={12} className="mb-3 text-start">
                  <p
                    style={
                      errors.disability && touched.disability && styles.errText
                    }
                  >
                    Have you ever received disability? If yes, additional fields
                    will appear to collect additional required information.
                  </p>
                  <Form.Check
                    inline
                    id="disability-radio-1"
                    type="radio"
                    label="Yes"
                    value="yes"
                    name="disability"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    id="disability-radio-2"
                    type="radio"
                    label="No"
                    value="no"
                    name="disability"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              {values.disability === "yes" && (
                <Row>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group className="mb-3" controlId="disabilityCompany">
                      <Form.Label>Comany Name</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Enter company name"
                          name="disabilityCompany"
                          onChange={handleChange}
                          style={
                            errors.disabilityCompany &&
                            touched.disabilityCompany
                              ? styles.errorStyle
                              : null
                          }
                        />
                        {errors.disabilityCompany &&
                          touched.disabilityCompany && (
                            <InputGroup.Text style={styles.inputGroupTextStyle}>
                              <AiOutlineExclamationCircle
                                style={styles.errIcon}
                              />
                            </InputGroup.Text>
                          )}
                      </InputGroup>
                      {errors.disabilityCompany && touched.disabilityCompany ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.disabilityCompany}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} className="mb-3 text-start">
                    <Form.Group className="mb-3" controlId="disabilityAmount">
                      <Form.Label>Disability Amount</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="$0,000.00"
                          name="disabilityAmount"
                          onChange={handleChange}
                          style={
                            errors.disabilityAmount && touched.disabilityAmount
                              ? styles.errorStyle
                              : null
                          }
                        />
                        {errors.disabilityAmount &&
                          touched.disabilityAmount && (
                            <InputGroup.Text style={styles.inputGroupTextStyle}>
                              <AiOutlineExclamationCircle
                                style={styles.errIcon}
                              />
                            </InputGroup.Text>
                          )}
                      </InputGroup>
                      {errors.disabilityAmount && touched.disabilityAmount ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.disabilityAmount}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </section>
            <hr />

            {/* Prior Convictions  */}
            <section>
              <div className="d-flex align-items-center">
                <h3 className="me-2">Prior Convictions</h3>
                <em>Required *</em>
              </div>
              <Row>
                <Col sm={12} className="mb-3 text-start">
                  <p
                    style={
                      errors.convictions &&
                      touched.convictions &&
                      styles.errText
                    }
                  >
                    Have you ever been convicted of a crime other than minor
                    traffic violations? If yes, additional fields will appear to
                    collect additional required information.
                  </p>
                  <Form.Check
                    inline
                    id="convictions-radio-1"
                    type="radio"
                    label="Yes"
                    value="yes"
                    name="convictions"
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    id="convictions-radio-2"
                    type="radio"
                    label="No"
                    value="no"
                    name="convictions"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              {values.convictions === "yes" && (
                <Row>
                  <Col sm={12} className="mb-3 text-start">
                    <Form.Group
                      className="mb-3"
                      controlId="convictionsExplanation"
                    >
                      <Form.Label>
                        If yes, please list ALL prior convictions and dates
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="convictionsExplanation"
                        onChange={handleChange}
                        style={
                          errors.convictionsExplanation &&
                          touched.convictionsExplanation
                            ? styles.errorStyle
                            : null
                        }
                      />
                      {errors.convictionsExplanation &&
                      touched.convictionsExplanation ? (
                        <div style={styles.fieldErrMessage}>
                          {errors.convictionsExplanation}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </section>

            {/* Submit Section  */}
            <section>
              <RequiredFieldMessage
                show={showReqFieldMessage ? true : false}
                reqMessage="Complete all required fields before continuing"
              />
              <SaveAndContinueUserMessage messageType="save and continue" />
              <Button
                variant="primary"
                type="submit"
                onClick={() => handleSaveAndContinue(values, errors)}
              >
                Save and Continue
              </Button>
            </section>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default BackgroundForm;
