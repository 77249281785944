import React, { useState, useEffect, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

export default function Welcome({ handleProgress, handleNav, user }) {
  const createUserForm = useRef(null);
  const deviceVerificationForm = useRef(null);

  const [smsSent, setSmsSent] = useState(false);
  const [deviceVerified, setDeviceVerified] = useState(false);

  // const [enable, setEnable] = useState({
  //   sendSms: false,
  //   confirmVeriCode: false,
  //   createUser: false,
  // });

  const handleSms = () => {
    const deviceForm = deviceVerificationForm.current;
    let deviceNumber = deviceForm["device-number"].value;

    if (deviceNumber.length === 10) {
      setSmsSent(true);
    }
  };

  const test = useRef(null);
  const verifyNumber = () => {
    const deviceForm = deviceVerificationForm.current;
    let veriCode = `${deviceForm["verification-number"].value}`;

    if (veriCode !== "") {
      setDeviceVerified(true);
    }
    test.current.scrollIntoView({ block: "center" });
  };

  const handleSubmit = () => {
    const userForm = createUserForm.current;
    const deviceForm = deviceVerificationForm.current;
    let userObj = {
      username: userForm["username"].value,
      password: userForm["password"].value,
      pwConfirmation: userForm["confirm-password"].value,
      mobileDevice: deviceForm["device-number"].value,
    };
    const hasEmptyValue = !Object.values(userObj).every((x) => !!x);
    if (hasEmptyValue) {
      console.log("Need to add message to UI");
    } else {
      handleNav("phase1-personal-information");
    }
  };

  const handleConstructionBtn = () => {
    handleNav("phase1-personal-information");
  };

  useEffect(() => {}, []);

  return (
    <main>
      {/* Instructions  */}
      <section className="main-section">
        <h2>Welcome</h2>
        <div className="main-section-content text-start">
          <p>
            Thank you for choosing to apply for a position with [provider name].
            Let's walk through the application process together.
          </p>
          <p>
            Below you will find a checklist to help you get organized and
            expedite the process.
          </p>
          <p>
            There are <strong>[x] </strong>phases in the application process.
            Access to each phase will be permitted only when all previous phase
            requirements are met.{" "}
          </p>
          <p>
            You can navigate current phase and completed phases freely, however,
            you will not be permitted to submit your application if Phase 1 and
            Phase 2 are incomplete.
          </p>
          <p>
            In the sections found below, you will be asked to verify your mobile
            device and to create an applicant account.
          </p>
          <p>
            You will not be able to continue application process without
            creating an account or verifying mobile device.{" "}
          </p>

          <p>
            Please contact <b>[contact name and info] </b>if you need
            application assistance.
          </p>
        </div>
      </section>

      {/* Checklist  */}
      <section className="main-section">
        <h2>Checklist</h2>
        <div className="main-section-content">
          <h3>Information</h3>
          <ul className="text-start">
            <li>
              Personal Information such as name, physical address, mailing
              address and social security number.
            </li>
            <li>Last 7 years or residence history</li>
            <li>Employment history (10 years?)</li>
            <li>2 References (One personal and one professional)</li>
            <li>
              Prior Education Information such as school name, location, and
              dates attended,
            </li>
          </ul>
          <h3>Doucments</h3>
          <ul className="text-start">
            <li>Driver's License or Personal Identification Card</li>
            <li>
              Social Security Care or Permanent Resident Care ("Green Card")
            </li>
            <li>TB Skin Test</li>
            <li>Covid Vaccination Card</li>
            <li>
              Prior Education Information such as school name, location, and
              dates attended,
            </li>
          </ul>
          <h3>Miscellaneous</h3>
          <ul className="text-start">
            <li>Access to mobile phone/device that can receive SMS messages</li>
          </ul>
          <Button onClick={handleConstructionBtn}>Next</Button>
        </div>
      </section>
    </main>
  );
}
