// import { Link } from "react-router-dom";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import { resetPasswordInputs } from "../formEntities/resetPasswordEntities";

const ResetPasswordForm = ({ handleFormikSubmit }) => {
  return (
    <section>
      <Formik
        // validationSchema={validationSchema}
        initialValues={{
          email: "",
          newPassword: "",
          confirmPassword: "",
        }}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form className="text-center" onSubmit={handleSubmit}>
            {console.log("Values:", values)}
            {resetPasswordInputs.map((input, index) => (
              <Form.Group
                key={input.name}
                className="mb-3 text-start"
                controlId={index}
              >
                <Form.Label>{input.label}</Form.Label>
                <InputGroup>
                  {input.prependIcon && <InputGroup.Text>@</InputGroup.Text>}
                  <Form.Control
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    onChange={handleChange}
                    value={values[input.name]}
                  />
                </InputGroup>
              </Form.Group>
            ))}
            <Button type="submit">Reset Password</Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ResetPasswordForm;
