import { Container } from "react-bootstrap";
import TaskComplete from "../common/taskComplete";
import GeneralForm from "../forms/pageForms/generalForm";

export default function General({ user, userData, handleNav, shake }) {
  return (
    <>
      {userData.general ? (
        <main>
          <section className="main-section">
            <h2>General Information</h2>
            <section className="main-section-content">
              <TaskComplete
                pageTitle={"General Information"}
                nextLink="phase2-residences"
              />
            </section>
          </section>
        </main>
      ) : (
        <main>
          {/* Instructions   */}
          <section className="main-section">
            <h2>General Information</h2>
            <div className="main-section-content text-start">
              <p>Please complete the form below.</p>
              <p>
                After all Page Requirements are met, please click "Submit"
                button to submit your choices and continue to "Personal
                Information"
              </p>
              <p className="alert alert-info">
                Page Requirements: Office of Interest, Position of Interest,
                Availability and Personal Transport are required before
                submitting form.
              </p>
            </div>
          </section>

          {/* Form  */}
          <section className="main-section">
            <div className="main-section-content">
              <Container>
                <GeneralForm handleNav={handleNav} shake={shake} />
              </Container>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
