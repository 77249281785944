import React, { useState } from "react";
import { Formik } from "formik";
import { Form, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import { AiOutlineExclamationCircle, AiOutlinePlus } from "react-icons/ai";
import {
  residenceInputs,
  validationSchema,
} from "../formEntities/residenceEntities";

const ResidenceForm = ({
  shake,
  handleExtendedAddress,
  extendedAddress,
  residences,
  handleFormikSubmit,
  initialValues,
}) => {
  const [address2, setAddress2] = useState(false);

  const styles = {
    errorStyle: {
      border: "2px solid red",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
    fieldErrMessage: {
      color: "red",
      paddingLeft: "5px",
    },
    errIcon: {
      fontSize: "20px",
      color: "red",
    },
    inputGroupTextStyle: {
      border: "1px solid red",
      background: "white",
    },
    errText: {
      color: "red",
    },
  };

  const handleValidations = (values) => {
    if (extendedAddress) {
      handleFormikSubmit(values);
    } else {
      let errors = {};
      if (
        values.fromDate &&
        values.fromDate !== "" &&
        !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
          values.fromDate
        )
      ) {
        errors.fromDate = "Invalid date format.  Use MM/DD/YYYY";
      }
      if (
        values.untillDate &&
        values.untillDate !== "" &&
        !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
          values.untillDate
        )
      ) {
        errors.untillDate = "Invalid date format.  Use MM/DD/YYYY";
      }
      if (values.zip && !/^[0-9]+$/i.test(values.zip)) {
        errors.zip = "Must be all numeric values";
      }
      return errors;
    }
  };

  const handleAdd = (values) => {
    if (
      !values.address1 ||
      !values.city ||
      !values.state ||
      !values.zip ||
      !values.fromDate ||
      !values.untillDate
    ) {
      shake();
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validate={(values) => handleValidations(values)}
      onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Container>
          {/* {console.log("Values:", values, "\nErrors:", errors)} */}
          <Form noValidate onSubmit={handleSubmit}>
            {/* Skip Step Checkbox */}
            {residences.length === 0 && (
              <section className="text-start">
                <p>
                  If you have lived at your current address for more than 7
                  years, you can let us know by clicking the checkbox below and
                  skip this step.
                </p>
                <Form.Group
                  className="skip-this-step text-start"
                  controlId="form-basic-checkbox"
                >
                  <Form.Check
                    type="checkbox"
                    label="I have lived at my current address for at least 7 years."
                    onChange={() => handleExtendedAddress()}
                    checked={extendedAddress ? true : false}
                    value={extendedAddress}
                  />
                </Form.Group>
              </section>
            )}
            <hr />

            {/* Inputs  */}
            <section>
              <Container>
                <Row>
                  {residenceInputs.map((input, index) => (
                    <Col sm={12} md={6} key={index}>
                      {input.name === "address2" && !address2 && (
                        <div
                          className="text-start d-flex align-items-center"
                          style={{ height: "100%" }}
                        >
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              textDecoration: "underline",
                            }}
                            onClick={() => setAddress2(true)}
                          >
                            <AiOutlinePlus className="me-2" /> Add Address Line
                            2
                          </button>
                        </div>
                      )}
                      <Form.Group
                        style={
                          input.name === "address2" && !address2
                            ? { visibility: "hidden", display: "none" }
                            : {}
                        }
                        className="mb-3 text-start"
                        controlId={index}
                      >
                        <Form.Label>{input.label}</Form.Label>
                        <InputGroup>
                          {input.prependIcon && (
                            <InputGroup.Text
                              id="inputGroupPrepend"
                              style={
                                errors[input.name] &&
                                touched[input.name] &&
                                styles.inputGroupTextStyle
                              }
                            >
                              @
                            </InputGroup.Text>
                          )}
                          <Form.Control
                            type={input.type}
                            placeholder={input.placeholder}
                            name={input.name}
                            onChange={handleChange}
                            maxLength={
                              input.name === "fromDate" ||
                              input.name === "untillDate"
                                ? 10
                                : input.name === "zip"
                                ? 5
                                : null
                            }
                            value={values[input.name]}
                            style={
                              errors[input.name] && touched[input.name]
                                ? styles.errorStyle
                                : null
                            }
                          />
                          {errors[input.name] && touched[input.name] && (
                            <InputGroup.Text style={styles.inputGroupTextStyle}>
                              <AiOutlineExclamationCircle
                                style={styles.errIcon}
                              />
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                        {errors[input.name] && touched[input.name] ? (
                          <div style={styles.fieldErrMessage}>
                            {errors[input.name]}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  ))}
                </Row>
              </Container>
            </section>

            <Button type="submit" onClick={() => handleAdd(values)}>
              Add
            </Button>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default ResidenceForm;
