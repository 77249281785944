import { object, string } from "yup";

const validationSchema = object().shape({
  address1: string()
    .required("Address is required")
    .min(2, "Address must have at least 2 characters")
    .max(100, "Address can't be longer than 100 characters"),
  city: string()
    .required("City is required")
    .min(2, "City must have at least 2 characters")
    .max(100, "City can't be longer than 100 characters")
    .required("Address is required"),
  state: string()
    .required("State is required")
    .min(2, "State must have at least 2 characters")
    .max(100, "State can't be longer than 100 characters"),
  zip: string()
    .required("Zip is required")
    .min(5, "Must be at least 5 characters")
    .max(5),
  fromDate: string()
    .min(10, "Enter in the following format MM/DD/YYYY")
    .max(10)
    .required("From Date is required"),
  untillDate: string()
    .min(10, "Enter in the following format MM/DD/YYYY")
    .max(10)
    .required("Untill date is required"),
});

const residenceInputs = [
  {
    name: "address1",
    label: "Address 1 *",
    type: "text",
    placeholder: "Enter Street Address",
  },
  {
    name: "address2",
    label: "Address 2",
    type: "text",
    placeholder: "Enter Street Address",
  },
  {
    name: "city",
    label: "City *",
    type: "text",
    placeholder: "Enter City",
  },
  {
    name: "state",
    label: "State *",
    type: "text",
    placeholder: "Enter State",
  },
  {
    name: "zip",
    label: "Zip *",
    type: "text",
    placeholder: "Enter Zip",
  },
  {
    name: "fromDate",
    label: "From Date *",
    type: "text",
    placeholder: "MM/DD/YYYY",
  },
  {
    name: "untillDate",
    label: "Untill Date *",
    type: "text",
    placeholder: "MM/DD/YYYY",
  },
];

export { residenceInputs, validationSchema };
