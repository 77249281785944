import React, { useState } from "react";
import { Formik } from "formik";
import { Form, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  employersInputs,
  validationSchema,
} from "../formEntities/employersEntities";
import { AiOutlinePlus } from "react-icons/ai";

const EmployersForm = ({
  shake,
  handleNoEmployers,
  noEmployers,
  handleFormikSubmit,
  initialValues,
  employers,
}) => {
  const [address2, setAddress2] = useState(false);

  const styles = {
    errorStyle: {
      border: "1px solid red",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
    fieldErrMessage: {
      color: "red",
      paddingLeft: "5px",
    },
    errIcon: {
      fontSize: "20px",
      color: "red",
    },
    inputGroupTextStyle: {
      border: "1px solid red",
      background: "white",
    },
    errText: {
      color: "red",
    },
  };

  const handleAdd = (values) => {
    if (
      !values.employerName ||
      !values.employerPhone ||
      !values.startDate ||
      !values.endDate ||
      !values.employerAddress1 ||
      !values.employerCity ||
      !values.employerState ||
      !values.employerZip ||
      !values.supervisorName ||
      !values.jobTitle ||
      !values.startRate ||
      !values.endRate ||
      !values.jobDuties ||
      !values.reasonForLeaving
    ) {
      shake();
    }
  };

  const handleValidations = (values) => {
    let errors = {};
    if (
      values.employerPhone &&
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/i.test(values.employerPhone)
    ) {
      errors.employerPhone = "Invalid format.  Use (###) ###-####";
    }

    if (
      values.startDate &&
      values.startDate !== "" &&
      !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
        values.startDate
      )
    ) {
      errors.startDate = "Invalid date format.  Use MM/DD/YYYY";
    }

    if (
      values.endDate &&
      values.endDate !== "" &&
      !/^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/i.test(
        values.endDate
      )
    ) {
      errors.endDate = "Invalid date format.  Use MM/DD/YYYY";
    }

    if (values.employerZip && !/^[0-9]+$/i.test(values.employerZip)) {
      errors.employerZip = "Must be all numeric values";
    }

    if (
      values.startRate &&
      !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/i.test(values.startRate)
    ) {
      errors.startRate = "Invalid Format.  Use XX.XX";
    }

    if (
      values.endRate &&
      !/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/i.test(values.endRate)
    ) {
      errors.endRate = "Invalid Format.  Use XX.XX";
    }
    return errors;
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validate={(values) => handleValidations(values)}
      onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <Container className="px-0">
          {/* {console.log("Values:", values, "\nErrors:", errors)} */}
          <Form noValidate onSubmit={handleSubmit}>
            {/* Skip Step Checkbox */}
            {employers && employers.length === 0 && (
              <section className="text-start">
                <p>
                  If you have never been employed, click the checkbox below.
                </p>
                <Form.Group
                  className="skip-this-step text-start"
                  controlId="form-basic-checkbox"
                >
                  <Form.Check
                    type="checkbox"
                    label="I have no employment information."
                    onChange={() => handleNoEmployers()}
                    checked={noEmployers ? true : false}
                    value={noEmployers}
                  />
                </Form.Group>
              </section>
            )}
            <hr />

            {/* Inputs  */}
            <section>
              <Container>
                <Row>
                  {employersInputs.map((input, index) => (
                    <Col
                      sm={12}
                      md={6}
                      className={
                        !address2 && input.name === "employerAddress2" && "mb-3"
                      }
                      key={index}
                    >
                      <>
                        {input.name === "employerAddress2" && !address2 && (
                          <div
                            className="text-start d-flex align-items-center"
                            style={{ height: "100%" }}
                          >
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                textDecoration: "underline",
                              }}
                              onClick={() => setAddress2(true)}
                            >
                              <AiOutlinePlus className="me-2" />
                              Add Address Line 2
                            </button>
                          </div>
                        )}
                        <Form.Group
                          style={
                            input.name === "employerAddress2" && !address2
                              ? { visibility: "hidden", display: "none" }
                              : {}
                          }
                          className="mb-3 text-start"
                          controlId={index}
                        >
                          <Form.Label>{input.label}</Form.Label>
                          <InputGroup>
                            {input.prependIcon && (
                              <InputGroup.Text
                                id="inputGroupPrepend"
                                style={
                                  errors[input.name] &&
                                  touched[input.name] &&
                                  styles.inputGroupTextStyle
                                }
                              >
                                @
                              </InputGroup.Text>
                            )}
                            <Form.Control
                              type={input.type}
                              placeholder={input.placeholder}
                              name={input.name}
                              onChange={handleChange}
                              maxLength={
                                input.name === "employerPhone"
                                  ? 14
                                  : input.name === "startDate" ||
                                    input.name === "endDate"
                                  ? 10
                                  : input.name === "employerZip"
                                  ? 5
                                  : 100
                              }
                              value={values[input.name]}
                              style={
                                errors[input.name] && touched[input.name]
                                  ? styles.errorStyle
                                  : null
                              }
                            />
                            {errors[input.name] && touched[input.name] && (
                              <InputGroup.Text
                                style={styles.inputGroupTextStyle}
                              >
                                <AiOutlineExclamationCircle
                                  style={styles.errIcon}
                                />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                          {errors[input.name] && touched[input.name] ? (
                            <div style={styles.fieldErrMessage}>
                              {errors[input.name]}
                            </div>
                          ) : null}
                        </Form.Group>
                      </>
                    </Col>
                  ))}
                </Row>
              </Container>
            </section>

            <Button type="submit" onClick={() => handleAdd(values)}>
              Add
            </Button>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default EmployersForm;
