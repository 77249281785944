import { object, string } from "yup";

const validationSchema = object().shape({});

const resetPasswordInputs = [
  {
    name: "email",
    label: "Email *",
    type: "email",
    placeholder: "example@email.com",
  },
  {
    name: "newPassword",
    label: "New Password *",
    type: "password",
    placeholder: "Enter New Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password *",
    type: "password",
    placeholder: "Enter Password",
  },
];

export { resetPasswordInputs, validationSchema };
