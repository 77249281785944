import React from "react";
import { useNavigate } from "react-router-dom";
import { GiCheckMark } from "react-icons/gi";
import { Button } from "react-bootstrap";

const TaskComplete = ({ pageTitle, nextLink }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${nextLink}`);
  };
  return (
    <div className="task-complete">
      <p>Congratulations on completing this task!</p>
      <GiCheckMark />
      <p>{pageTitle} saved successfully!</p>
      {nextLink && <Button onClick={handleClick}>Next</Button>}
    </div>
  );
};

export default TaskComplete;
