import { object, string } from "yup";

const referencesInputs = [
  {
    name: "referenceName",
    label: "Reference Name *",
    type: "text",
    placeholder: "Enter Reference Name",
  },
  {
    name: "referencePhone",
    label: "Reference Phone *",
    type: "text",
    placeholder: "(###) ###-####",
  },
  {
    name: "referenceEmail",
    label: "Reference Email *",
    type: "email",
    placeholder: "example@email.com",
    prependIcon: "@",
  },
  {
    name: "referenceRelationship",
    label: "Reference Relationship *",
    type: "text",
    placeholder: "Enter Reference Relationship",
  },
  {
    name: "referenceAddress1",
    label: "Reference Street Address",
    type: "text",
    placeholder: "Enter Reference Street Address",
  },
  {
    name: "referenceAddress2",
    label: "Address Line 2",
    type: "text",
    placeholder: "Enter Additional Address Information.  Suite #, Apt # etc.",
  },
  {
    name: "referenceCity",
    label: "Reference City",
    type: "text",
    placeholder: "Enter Reference City",
  },
  {
    name: "referenceState",
    label: "Reference State",
    type: "text",
    placeholder: "Enter Reference State",
  },
  {
    name: "referenceZip",
    label: "Reference Zip",
    type: "text",
    placeholder: "Enter Reference Zip",
  },
];

const validationSchema = object().shape({
  referenceName: string()
    .required("Reference name is required")
    .min(2, "Must have at least 2 characters")
    .max(100, "Can't be longer than 100 characters"),
  referencePhone: string().required("Reference phone is required"),
  referenceEmail: string().required("Reference email is required"),
  referenceRelationship: string().required(
    "Reference relationship is required"
  ),
  referenceType: string().required("Reference type is required"),
});

export { referencesInputs, validationSchema };
