import React, { useState } from "react";
import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  ProgressBar,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function NavComponent({ userData, nav, now, enablePhases }) {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg=""
      variant=""
      className="fixed flex-lg-column text-lg-start"
    >
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="flex-column" navbarScroll>
          {/* Phase 1  */}
          <section style={{ marginTop: "1rem" }}>
            <p>Phase 1</p>
            <LinkContainer to={nav[0].route}>
              <Nav.Link
                active={false}
                className={userData.username ? "complete" : "in-progress"}
              >
                Welcome
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to={nav[1].route}
              disabled={!userData.username ? true : false}
            >
              <Nav.Link
                active={false}
                className={userData.personalInfo ? "complete" : "in-progress"}
              >
                Personal Information
              </Nav.Link>
            </LinkContainer>
          </section>

          {/* Phase 2  */}
          <section>
            <p className={enablePhases.phase2 ? "" : "phase-disabled"}>
              Phase 2
            </p>
            <LinkContainer
              to={nav[2].route}
              disabled={enablePhases.phase2 ? false : true}
            >
              <Nav.Link
                active={false}
                className={userData.general ? "complete" : "in-progress"}
              >
                {nav[2].name}
              </Nav.Link>
            </LinkContainer>

            <LinkContainer
              to={nav[3].route}
              disabled={enablePhases.phase2 ? false : true}
            >
              <Nav.Link
                active={false}
                className={
                  userData.residenceInfo && userData.residenceInfo.isComplete
                    ? "complete"
                    : "in-progress"
                }
              >
                Residence History
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to={nav[4].route}
              disabled={enablePhases.phase2 ? false : true}
            >
              <Nav.Link
                active={false}
                className={
                  userData.employment && userData.employment.isComplete
                    ? "complete"
                    : "in-progress"
                }
              >
                Employment History
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to={nav[5].route}
              disabled={enablePhases.phase2 ? false : true}
            >
              <Nav.Link
                active={false}
                className={
                  userData.referenceInfo && userData.referenceInfo.isComplete
                    ? "complete"
                    : "in-progress"
                }
              >
                References
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to={nav[6].route}
              disabled={enablePhases.phase2 ? false : true}
            >
              <Nav.Link
                active={false}
                className={
                  userData.education && userData.education.isComplete
                    ? "complete"
                    : "in-progress"
                }
              >
                Education
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to={nav[7].route}
              disabled={enablePhases.phase2 ? false : true}
            >
              <Nav.Link
                active={false}
                className={userData.backgroundInfo ? "complete" : "in-progress"}
              >
                Background Information
              </Nav.Link>
            </LinkContainer>
          </section>

          {/* Phase 3  */}
          <section>
            <p className={enablePhases.phase3 ? "" : "phase-disabled"}>
              Phase 3
            </p>
            <LinkContainer
              to={nav[8].route}
              disabled={enablePhases.phase3 ? false : true}
            >
              <Nav.Link
                active={false}
                className={
                  userData.applicationStatus === "complete"
                    ? "complete"
                    : "in-progress"
                }
              >
                Submit Application
              </Nav.Link>
            </LinkContainer>
          </section>

          {/* Phase 4 */}
          <section>
            <p
              className={enablePhases.phase4 ? "" : "phase-disabled"}
              style={{ marginBottom: "0", fontWeight: "800" }}
            >
              Phase 4
            </p>
            <LinkContainer
              to={nav[9].route}
              disabled={enablePhases.phase4 ? false : true}
            >
              <Nav.Link
                active={false}
                className={
                  userData.documents && userData.documents.isComplete
                    ? "complete"
                    : "in-progress"
                }
              >
                Upload Documents
              </Nav.Link>
            </LinkContainer>
            <NavDropdown
              title="Pre Requisites"
              id="nav-dropdown"
              disabled={enablePhases.phase4 ? false : true}
            >
              <NavDropdown.Item eventKey="4.1">Pre Req 1</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">Pre Reg 2</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3">Pre Req 3</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item eventKey="4.4">LMS</NavDropdown.Item>
            </NavDropdown>
          </section>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
