import React, { useState, useEffect } from "react";
import userService from "../../services/userService";
import BackgroundForm from "../forms/pageForms/backgroundForm";
import TaskComplete from "../common/taskComplete";
import { Button } from "react-bootstrap";
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import SaveAndContinueUserMessage from "../common/userMessages/saveAndContinueMessage";
export default function BackgroundInformation({
  user,
  userData,
  handleNav,
  shake,
  updatePage,
  enablePhases,
  allTasksCompleted,
}) {
  const [modalShow, setModalShow] = useState(false);

  const onModalHide = () => {
    setModalShow(false);
    updatePage();
  };

  const handleFormikSubmit = (values) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || !values[key]) {
        delete values[key];
      }
    });

    if (!allTasksCompleted) {
      setModalShow(true);
      userService.saveUserData(user, "backgroundInfo", values);
    } else {
      handleNav("phase3-submit-application");
      userService.saveUserData(user, "backgroundInfo", values);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {userData.backgroundInfo ? (
        <main>
          <section className="main-section">
            <h2>Background Information</h2>
            <section className="main-section-content">
              <TaskComplete
                pageTitle="Background Information"
                nextLink={
                  enablePhases.phase3 ? "phase3-submit-application" : ""
                }
              />
            </section>
          </section>
        </main>
      ) : (
        <main>
          <Modal show={modalShow} centered>
            {" "}
            <ModalHeader as="span" className="alert alert-danger">
              <ModalTitle as="h4">Incomplete Tasks!</ModalTitle>
            </ModalHeader>
            <ModalBody as="section">
              To proceed to phase 3, it is mandatory to complete all tasks in
              both phase 1 and phase 2. Please click "Confirm" to return to the
              application and ensure the completion of all tasks highlighted in
              red in Phase 2.
            </ModalBody>
            <ModalFooter as="footer">
              <Button onClick={onModalHide}>Confirm</Button>
            </ModalFooter>
          </Modal>
          {/* Page Instructions  */}
          <section className="main-section">
            <h2>Background Information</h2>
            <section className="main-section-content text-start">
              <p>Answer all of the questions in the form below.</p>
              <p>
                If you answer yes to any of the questions, provide the
                additional information requested.
              </p>
              <p>If your answer is no, continue to the next question. </p>
              <p className="alert alert-info">
                Page Requirements: Answer all questions and additional
                information as needed.
              </p>
            </section>
          </section>
          {/* Form Section */}
          <section className="main-section">
            <section className="main-section-content">
              <BackgroundForm
                // handleSaveAndContinue={handleSaveAndContinue}
                shake={shake}
                handleFormikSubmit={handleFormikSubmit}
                // showReqFieldMessage={showReqFieldMessage}
              />
            </section>
          </section>
        </main>
      )}
    </>
  );
}
