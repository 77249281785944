
export default function Version({}) {
  return (
    <p
      style={{
        fontSize: ".55rem",
      }}
    >
      v0.0.2
    </p>
  );
}
