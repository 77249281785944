import React, { useState } from "react";
import userService from "../../services/userService";
import { Form, Button } from "react-bootstrap";
import TaskComplete from "../common/taskComplete";

export default function UploadDocuments({ shake, userData, updatePage }) {
  const [formState, setFormState] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newFormState = { ...formState, [name]: value };
    setFormState({ ...formState, [e.target.name]: e.target.value });
    checkCitizenshipDocs(newFormState);
    checkIdentificationDocs(newFormState);
  };

  const [driverLicReqs, setDriverLicReqs] = useState(true);
  const [stateIdReqs, setStateIdReqs] = useState(true);
  const checkIdentificationDocs = (newFormState) => {
    if (newFormState.driverLicense) {
      setStateIdReqs(false);
    } else if (newFormState.stateId) {
      setDriverLicReqs(false);
    } else {
      setStateIdReqs(true);
      setDriverLicReqs(true);
    }
  };

  const [bcReqs, setBcReqs] = useState(true);
  const [sscReqs, setSscReqs] = useState(true);
  const [passportReqs, setPassportReqs] = useState(true);
  const checkCitizenshipDocs = (newFormState) => {
    if (newFormState.birthCertificate) {
      setSscReqs(false);
      setPassportReqs(false);
    } else if (newFormState.socialSecurityCard) {
      setBcReqs(false);
      setPassportReqs(false);
    } else if (newFormState.passport) {
      setBcReqs(false);
      setSscReqs(false);
    } else {
      setBcReqs(true);
      setSscReqs(true);
      setPassportReqs(true);
    }
  };

  const [validated, setValidated] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let uploadedDocuments = {
      driverLicense: formState.driverLicense,
      stateId: formState.stateId,
      birthCertificate: formState.birthCertificate,
      socialSecurityCard: formState.socialSecurityCard,
      passport: formState.passport,
      covidVaccination: formState.covidVaccine,
      tbTest: formState.tbTest,
    };
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      shake();
    } else {
      updatePage();
      checkCitizenshipDocs(formState);
      checkIdentificationDocs(formState);
      userService.saveUserData("lizzy@sembracare.com", "documents", {
        isComplete: true,
        documents: uploadedDocuments,
        covidVaccinated: formState.covidVaccinated,
        tbTested: formState.tbTested,
      });
    }
    setValidated(true);
  };

  return (
    <>
      {userData.documents ? (
        <main>
          <section className="main-section">
            <h2>Upload Documents</h2>
            <section className="main-section-content">
              <TaskComplete pageTitle="Documents" />
            </section>
          </section>
        </main>
      ) : (
        <main>
          {/* Instructions  */}
          <section className="main-section">
            <h2>Upload Documents</h2>
            <div className="main-section-content text-start">
              <div className="alert alert-warning">
                <p>*** Dev Note ***</p>
                <p>This page is not functioning yet </p>
                <p>Final functionality will be added after AWS deployment </p>
              </div>
              <p>Here you can upload your documents.</p>
            </div>
          </section>

          {/* Form Section */}
          <section className="main-section">
            <h2>Upload Form</h2>
            <section className="main-section-content">
              {/* Form Section - Instructions  */}
              <section className="text-start mb-5">
                <p>Use the form inputs below to upload required documents.</p>
                <p>
                  If TB skintest results are required for you position, and you
                  have not had a TB skintest within 1 year, we will help with
                  that after an offer is extended.
                </p>
                <hr className="my-5" />
              </section>
              {/* Form Section - Form  */}
              <Form validated={validated} noValidate onSubmit={handleSubmit}>
                {/* ID*/}
                <section className="text-start">
                  <div className="d-flex align-items-center">
                    <h3 className="me-2">Identification</h3>
                    <em>Required *</em>
                  </div>
                  {/* <strong> */}
                  <p>Upload one of the following</p>
                  {/* </strong> */}
                  <Form.Group
                    controlId="fileDriverLic"
                    className="mb-3 text-start"
                  >
                    <Form.Label>Driver's License</Form.Label>
                    <Form.Control
                      type="file"
                      name="driverLicense"
                      onChange={handleInputChange}
                      required={driverLicReqs ? true : false}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="fileIdCard"
                    className="mb-3 text-start"
                  >
                    <Form.Label>North Carolina State ID Card</Form.Label>
                    <Form.Control
                      type="file"
                      name="stateId"
                      onChange={handleInputChange}
                      required={stateIdReqs ? true : false}
                    />
                  </Form.Group>
                  <hr className="my-5" />
                </section>

                {/* Birth Certificate, SSC or passport upload*/}
                <section className="text-start">
                  <div className="d-flex align-items-center">
                    <h3 className="me-2">Citizenship</h3>
                    <em>Required *</em>
                  </div>
                  <p>Upload one of the following</p>
                  <Form.Group
                    controlId="fileBirthCert"
                    className="mb-3 text-start"
                  >
                    <Form.Label>Birth Certificate</Form.Label>
                    <Form.Control
                      type="file"
                      name="birthCertificate"
                      onChange={handleInputChange}
                      required={bcReqs ? true : false}
                    />
                  </Form.Group>

                  <Form.Group controlId="fileSsc" className="mb-3 text-start">
                    <Form.Label>Social Security Card</Form.Label>
                    <Form.Control
                      type="file"
                      name="socialSecurityCard"
                      onChange={handleInputChange}
                      required={sscReqs ? true : false}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="filePassport"
                    className="mb-3 text-start"
                  >
                    <Form.Label>Passport</Form.Label>
                    <Form.Control
                      type="file"
                      name="passport"
                      onChange={handleInputChange}
                      required={passportReqs ? true : false}
                    />
                  </Form.Group>
                  <hr className="my-5" />
                </section>

                {/* Covid Vaccination  */}
                <section className="text-start">
                  <div className="d-flex align-items-center">
                    <h3 className="me-2">COVID Vaccination</h3>
                    <em>Required *</em>
                  </div>
                  {/* <strong> */}
                  <p>Are you fully vaccinated against COVID-19?</p>
                  {/* </strong> */}

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      id="inline-radio-1"
                      name="covidVaccinated"
                      value="yes"
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      id="inline-radio-2"
                      name="covidVaccinated"
                      value="no"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="fileCovidVax"
                    className="mb-3 text-start"
                  >
                    <Form.Label>Upload COVID Vaccine</Form.Label>
                    <Form.Control
                      type="file"
                      name="covidVaccine"
                      disabled={
                        formState.covidVaccinated === "yes" ? false : true
                      }
                      required={
                        formState.covidVaccinated === "no" ? false : true
                      }
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <p>
                    ** If you are not fully vaccinated against COVID-19, we can
                    assist with getting you vaccinated after you are hired.
                    COVID vaccine is <strong>REQUIRED</strong> for all clinical
                    positions.
                  </p>
                  <hr className="my-5" />
                </section>

                {/* TB Skin test  */}
                <section className="text-start">
                  <div className="d-flex align-items-center">
                    <h3 className="me-2">TB Skin Test</h3>
                    <em>Required *</em>
                  </div>
                  {/* <strong> */}
                  <p>Have you had a TB skin test within 12 months?</p>
                  {/* </strong> */}
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      id="tb-test-1"
                      name="tbTested"
                      value="yes"
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      id="tb-test-2"
                      name="tbTested"
                      value="no"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="fileTbTest"
                    className="mb-3 text-start"
                  >
                    <Form.Label>Upload TB skintest results</Form.Label>
                    <Form.Control
                      type="file"
                      name="tbTest"
                      disabled={formState.tbTested === "yes" ? false : true}
                      required={formState.tbTested === "yes" ? true : false}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <p>
                    ** If you are have not had a TB skin test withing [12
                    months?], we can assist with getting you tested after you
                    are hired. TB skin tests are
                    <strong> REQUIRED</strong> in for all clinical positions.
                  </p>
                </section>
                <Button type="submit">Submit</Button>
              </Form>
            </section>
          </section>
        </main>
      )}
    </>
  );
}
