import http from "./httpService";
import apiUrl from "../config.json";

function getUserData(username) {
  const apiEndpoint = apiUrl.apiUrl + "/employee-applications/getUserData"
  return http.get(apiEndpoint, { username: username })
}

function saveUserData(username, title, saveData) {
  const apiEndpoint = apiUrl.apiUrl + "/employee-applications/saveUserData"
  return http.post(apiEndpoint, { [title]: saveData });
}

export default {
  getUserData,
  saveUserData
}