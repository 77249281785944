import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function PageNotFound({}) {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <main
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <p>404 - Page Not Found will live here</p>
        <div className="d-flex justify-content-between">
          <Button onClick={() => navigate("/")}>Home</Button>
          <Button onClick={() => navigate(-1)}>Back</Button>
        </div>
      </div>
    </main>
  );
}
